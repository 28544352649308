import { type ReactNode, useState } from "react";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button } from "./button.component";

interface CarouselItem {
  image: string;
  title: string;
  description: string | ReactNode;
  tag?: string;
  buttonsSlot?: ReactNode;
}

interface TextImageCarouselProps {
  items: CarouselItem[];
}

export function TextImageCarousel({ items }: TextImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const activeIndex = (currentIndex + items.length) % items.length;

  const swipeToIndex = (swipeDirection: number) => {
    setCurrentIndex(currentIndex + swipeDirection);
  };

  const skipToIndex = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative flex flex-col items-center h-full w-full max-w-screen-xl mx-auto">
      <Button
        onClick={() => swipeToIndex(-1)}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 rounded-full text-sky-600 border border-sky-600 p-1 hidden sm:flex"
        color="transparent"
        noPadding
      >
        <ChevronLeftIcon className="size-5" />
      </Button>

      <div className="relative flex flex-col lg:flex-row w-11/12 h-fit overflow-hidden lg:px-4 lg:gap-x-12">
        <div className="w-full flex justify-center items-center overflow-hidden">
          <motion.img
            key={currentIndex}
            src={items[activeIndex].image}
            alt={items[activeIndex].title}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
            className="max-h-96 object-contain will-change-[transform,opacity]"
          />
        </div>
        <div className="w-full flex items-center px-2 lg:px-0">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
            className="space-y-5"
          >
            {items[activeIndex].tag && (
              <p className="uppercase font-bold text-sm mb-4">
                {items[activeIndex].tag}
              </p>
            )}
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold mb-8">
              {items[activeIndex].title}
            </h2>
            <p className="text-darkGray font-normal leading-6 lg:mr-10">
              {items[activeIndex].description}
            </p>
            {items[activeIndex].buttonsSlot}
          </motion.div>
        </div>
      </div>
      <Button
        onClick={() => swipeToIndex(1)}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 text-sky-600 rounded-full border border-sky-600 p-1 hidden sm:flex"
        color="transparent"
        noPadding
      >
        <ChevronRightIcon className="size-5" />
      </Button>

      <div className="flex justify-center mt-10 lg:mt-6 space-x-3">
        {items.map((_, index) => (
          <button
            key={index}
            onClick={() => skipToIndex(index)}
            className={`size-2 rounded-full transition-all ${
              index === activeIndex ? "bg-sky-500 scale-125" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
}
