import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import type { ProductCategory, Vendor } from "prisma-types";
import { Form, useNavigate } from "@remix-run/react";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

export type Products = {
  id: string;
  slug: string;
  title: string;
  logo_file_id: string;
  vendor_name: string;
  vendor_logo_file_id: string;
}[];

export function AutocompleteSearchBar() {
  const [products, setProducts] = useState<Products>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const handleSearch = _.debounce(async (e: any) => {
    const searchTerm = e.target.value;
    const response = await fetch(
      `/search-bar-results?query=${searchTerm.toString()}`
    );
    const { products, categories, vendors } = await response.json();

    setProducts(products);
    setVendors(vendors);
    setCategories(categories);

    const hasResults =
      !_.isEmpty(products) || !_.isEmpty(categories) || !_.isEmpty(vendors);
    setShowResults(hasResults);
  }, 300);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target as Node)
      ) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Form method="get" action="/search" className="flex">
        <input
          autoComplete="off"
          onChange={handleSearch}
          name="query"
          id="search-bar"
          type="text"
          className="rounded-3xl p-3 px-4 flex-grow lg:mr-3 mr-1 text-black border-0 focus:border-0 focus:ring-0 text-sm lg:text-base caret-emerald-500"
          placeholder="Enter product name, service category, etc"
        />
        <button
          className="rounded-3xl p-3 border border-white bg-sky-500"
          type="submit"
          id="search-button"
        >
          <MagnifyingGlassIcon className="h-5"></MagnifyingGlassIcon>
        </button>
      </Form>
      {showResults && (
        <div
          className="w-full relative flex justify-center"
          ref={searchResultsRef}
        >
          <div className="absolute lg:w-11/12 z-50 right-0 left-0 bg-white shadow-md border border-gray-200 mt-2 rounded-md text-gray-900">
            <div className="p-4 h-auto max-h-96 overflow-y-auto space-y-4 lg:space-y-0">
              {!_.isEmpty(products) && (
                <div className="lg:flex items-start lg:divide-x">
                  <div className="text-left text-sm lg:text-base font-semibold w-40 h-auto mt-2">
                    Products ({products.length})
                  </div>
                  <div className="mt-2 lg:mt-0 lg:pl-6 lg:pb-8 w-full lg:w-9/12 flex justify-start flex-col items-start space-y-3 lg:space-y-0">
                    {products.map(product => (
                      <div
                        key={product.id}
                        className="flex space-x-3 items-center lg:p-2 w-full rounded-md cursor-pointer lg:hover:bg-gray-50 ease-in-out duration-200"
                        onClick={() => {
                          navigate(`/products/${product.slug}`);
                        }}
                      >
                        <div
                          className="w-12 h-12 rounded-md bg-white border border-gray-100 bg-center bg-contain bg-no-repeat"
                          style={{
                            backgroundImage: product.logo_file_id
                              ? `url(/files/${product.logo_file_id})`
                              : product.vendor_logo_file_id
                              ? `url(/files/${product.vendor_logo_file_id})`
                              : "url(/assets/default-logo.png)",
                          }}
                        ></div>
                        <div className="text-left">
                          <div className="font-semibold leading-tight text-sm lg:text-base">
                            {product.title}
                          </div>
                          <div className="font-light leading-tight text-xs lg:text-sm">
                            by {product.vendor_name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {!_.isEmpty(categories) && (
                <div className="lg:flex items-start lg:divide-x">
                  <div className="text-left text-sm lg:text-base font-semibold w-40 h-auto mt-2">
                    Categories ({categories.length})
                  </div>
                  <div className="mt-2 lg:mt-0 lg:pl-6 lg:pb-8 w-full lg:w-9/12 flex justify-start flex-col items-start space-y-3 lg:space-y-0">
                    {categories.map(category => (
                      <div
                        className="text-left lg:p-3 w-full text-sm lg:text-base font-medium rounded-md cursor-pointer lg:hover:bg-gray-50 ease-in-out duration-200"
                        onClick={() => {
                          navigate(`/categories/${category.slug}`);
                        }}
                        key={category.id}
                      >
                        {category.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {!_.isEmpty(vendors) && (
                <div className="lg:flex items-start lg:divide-x">
                  <div className="text-left text-sm lg:text-base font-semibold w-40 h-auto mt-2">
                    Vendors ({vendors.length})
                  </div>
                  <div className="mt-2 lg:mt-0 lg:pl-6 lg:pb-8 w-full lg:w-9/12 flex justify-start flex-col items-start space-y-3 lg:space-y-0">
                    {vendors.map(vendor => (
                      <div
                        key={vendor.id}
                        className="flex space-x-3 items-center lg:p-2 w-full rounded-md cursor-pointer lg:hover:bg-gray-50 ease-in-out duration-200"
                        onClick={() => {
                          navigate(`/vendors/${vendor.slug}`);
                        }}
                      >
                        <div
                          className="w-12 h-12 rounded-md bg-white border border-gray-100 bg-center bg-contain bg-no-repeat"
                          style={{
                            backgroundImage: vendor.logo_file_id
                              ? `url(/files/${vendor.logo_file_id})`
                              : "url(/assets/default-logo.png)",
                          }}
                        ></div>
                        <div className="text-left">
                          <div className="font-semibold text-sm lg:text-base">
                            {vendor.name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
